<template>
  <div>
    <!-- <v-card height="100%" width="100%" color="#2d3a54" class="align-center"> -->

       <v-img  class="white--text align-center"
      
         style=" height:100vh"
        src="../assets/pp.jpg"
      >
      <v-layout wrap justify-left py-4 >
        <!-- <v-flex xs12 pt-4 pb-2 > 
       <span>  <v-img width="200px" height="30px" contain src="../assets/Group 8314.svg"> </v-img></span> 
       <span style="color:white" >  <v-img class="mx-auto" width="200px" height="30px" contain src="../assets/Group 8314.svg"> </v-img>   </span>
        </v-flex> -->
        
        <v-flex xs6 sm3 md2  lg1 pl-16 pb-5 pb-sm-0 py-2 >
           <v-img  style="padding-left:0px" width="200px" height="60px" contain src="../assets/Group 8314.svg" ></v-img>
        </v-flex>
         <!-- <v-flex xs6 sm8 md9  text-left align-self-center pb-5 pb-sm-0 pl-3 class="hidden-xs-only">
          <span style="color:white font-family:Times New Roman;font-size:20px" > CYBER HAWK</span>
        </v-flex> -->
        <v-flex xs6 sm6 md3  lg4 pl-16 pb-5 pb-sm-0 pr-8 py-2 >
           <v-img  style="padding-left:0px" width="200px" height="60px" contain src="../assets/wti.jpg"></v-img>
        </v-flex>
        <v-flex>
          <v-layout wrap justify-center pt-sm-2 pt-md-1 pt-lg-0>

         
        <v-flex xs12 sm8 md6 xl5 pb-7>
          <v-card width="100%">
            <v-layout wrap justify-center text-center pa-12>
              <!-- <v-flex xs6 style="background-color:grey">
           <v-img style="padding-left:0px" width="200px" height="60px" contain src="../assets/Group 8314.svg"></v-img>
              
              </v-flex>
              <v-flex  xs6 style="background-color:grey">
           <v-img class="mx-auto" style="padding-left:0px" width="200px" height="100px" contain src="../assets/wti.jpg"></v-img>

              </v-flex> -->
              <v-flex xs12 py-3>
            <b> <span>Click the download button below to download the app</span></b>
              </v-flex>
              <v-flex xs12>
                <a href="https://www.wti.org.in/wp-content/uploads/2021/Cyberhawk_V_1.1.07.apk" class="dec">
                <v-btn color="green" dark x-large>
                  <v-icon left dark> mdi-cloud-download </v-icon>
                  Download
                </v-btn>
                </a>
              </v-flex>
              <v-flex xs12 py-3>
                <span style="font-size:12px">CYBER HAWK App Version 1.1.7</span>
              </v-flex>
              <v-flex xs11>
                <v-layout wrap >
                  <v-flex xs12>
                    <v-card color="#e8e2e1">
                      <v-layout wrap pa-5>
                        <v-flex xs12 text-left pb-3> Version: 1.1.7 </v-flex>
                        <v-flex xs12 text-left> What's New </v-flex>
                        <v-flex xs12 pl-6 text-left>
                       <span class="dot"></span>   <span>  Performance Improvement.</span> <br />
                         <span class="dot"></span>   <span>  UI Changes.</span> <br>
                         <span class="dot"></span>   <span>  Bug fixes</span>

                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                  <v-flex xs12 text-left pt-5 pb-3>
                  <b>  <span>Instructions to update the app</span></b>
                  </v-flex>
                  <v-flex xs12 pl-5 text-left>
                    
                    <span
                      >1.Uninstall the old version of the app on your
                      phone.</span
                    >
                    <br />
                    <span
                      >2.Download the latest version of the app by clicking the
                      download button above.</span
                    >
                    <br />
                    <span>3.Install the newly downloaded apk</span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
         </v-layout>
        </v-flex>
      </v-layout>
       </v-img>
    <!-- </v-card> -->
  </div>
</template>

<script>
export default {
  name: "HelloWorld",

  data: () => ({
    ecosystem: [
      {
        text: "vuetify-loader",
        href: "https://github.com/vuetifyjs/vuetify-loader",
      },
      {
        text: "github",
        href: "https://github.com/vuetifyjs/vuetify",
      },
      {
        text: "awesome-vuetify",
        href: "https://github.com/vuetifyjs/awesome-vuetify",
      },
    ],
    importantLinks: [
      {
        text: "Documentation",
        href: "https://vuetifyjs.com",
      },
      {
        text: "Chat",
        href: "https://community.vuetifyjs.com",
      },
      {
        text: "Made with Vuetify",
        href: "https://madewithvuejs.com/vuetify",
      },
      {
        text: "Twitter",
        href: "https://twitter.com/vuetifyjs",
      },
      {
        text: "Articles",
        href: "https://medium.com/vuetify",
      },
    ],
    whatsNext: [
      {
        text: "Explore components",
        href: "https://vuetifyjs.com/components/api-explorer",
      },
      {
        text: "Select a layout",
        href: "https://vuetifyjs.com/getting-started/pre-made-layouts",
      },
      {
        text: "Frequently Asked Questions",
        href: "https://vuetifyjs.com/getting-started/frequently-asked-questions",
      },
    ],
  }),
};
</script>
<style >
.dot {
  height: 5px;
  width: 5px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
}
.dec {
  text-decoration:none;
}
</style>
